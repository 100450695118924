<template>
  <div class="bgcontent">
    <Header>
         <template slot="title">邀请</template>
    </Header>
    <div class="codeContent">
        <div id="qrcode"  class="codeimg">
            <canvas id="canvas" style="height: 150px;width: 150px;margin-top: 30px;"></canvas>
        </div>
        <div class="linkcontent">
            <div class="linktxt">{{ url }}</div>
            <div class="linkbtn" @click="copyLink" id="tag">复制链接</div>
        </div>
        <div class="stepcontent">
            <div class="card">
                <div class="stepbox">
                    第一步
                </div>
                <div class="acc">准备账号</div>
                <div class="txt">
                    1.下载安装钱包 <br>
                    2.选择币安智能链<br>
                    3.创建钱包账号
                </div>
            </div>
            <div class="card">
                <div class="stepbox">
                    第二步
                </div>
                <div class="acc">绑定关系</div>
                <div class="txt">
                    1.扫码或通过链接邀请用户进入DAPP<br>
                    2.进入页面填写邀请地址绑定
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/header.vue';
import Clipboard from 'clipboard'
import QRCode from 'qrcode'
import { Toast } from 'vant'
export default {
    components: {
        Header,
        QRCode: QRCode
    },
    data(){
        return {
            address:'',
            url:'',
        }
    },
    created(){


    },
    activated() {
        this.address = sessionStorage.getItem("address");
        if(this.address){
            this.url  = contractConfig.inviteUrl+'app/index.html?p_address='+this.address;
            this.getQrcode();
        }
    },
    methods: {
        //复制
        copyLink(){
            var _this = this;
            let clipboard = new Clipboard('#tag', {
                text: function () {
                    return _this.url;
                }
            })
            clipboard.on('success', e => {
                Toast('复制成功');
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', e => {
                Toast('复制失败');
                clipboard.destroy()
            })
        },
        //生成二维码
        getQrcode(){
            var canvas = document.getElementById('canvas')
            QRCode.toCanvas(canvas, this.url , function (error) {
                if (error) console.error(error)
                console.log('success!');
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.bgcontent{
    background: #102B1D;
    min-height: 100vh;
}
.codeContent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .codeimg {
        margin-top: 48px;
        box-sizing: border-box;
        width: 480px;
        height: 530px;
        background: linear-gradient(180deg, rgba(70, 255, 141, 0.2) 0%, rgba(139, 166, 149, 0.2) 100%);
        box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(8px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 24px;
        text-align: center;
        line-height:  530px;
    }
    .linkcontent {
        width: 600px;
        height: 96px;
        background: #29332D;
        border-radius: 16px;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        .linktxt {
            line-height: 96px;
            font-weight: 400;
            font-size: 32px;
            color: #8BA695;
            margin-left: 32px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width:60%;
        }
        .linkbtn {
            padding: 0x 16px;
            width: 208px;
            height: 88px;
            background: #27CC7F;
            border-radius: 16px;
            font-weight: 600;
            font-size: 36px;
            line-height: 88px;
            text-align: center;
            color: #202823;
        }
    }
    .stepcontent {
        width: 100%;
        margin-top: 120px;
        display: flex;
        justify-content: space-around;
        .card {
            padding: 20px;
            width: 328px;
            height: 284px;
            background: #202823;
            border-radius: 24px;
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
            .stepbox {
                text-align: center;
                width: 100px;
                height: 40px;
                font-weight: 400;
                font-size: 24px;
                line-height: 36px;
                color: #27C7CC;
                border:1px solid #27C7CC;
                border-radius: 8px;
            }
            .acc {
                margin-left: 20px;
                font-weight: 600;
                font-size: 28px;
                line-height: 40px;
                color: #D6FFE3;
            }
            .txt {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                color: #B6D9C3;
                position: relative;
                line-height: 38px;
            }
        }
    }
}
</style>